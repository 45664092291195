import { useState, useEffect } from "react";
import { useRouteLoaderData, useParams, Outlet } from "react-router-dom";
import { ComunaSidePanel } from "../components/ComunaSidePanel";

import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";

import ApplicationProfileTab from "../components/ApplicationProfileTab";
import ApplicationmessageTab from "../components/ApplicationMessagesTab";
import TechnicalInterviewComponent from "../components/TechnicalInterview/TechnicalInterviewComponent";
import NewTechnicalInterviewComponent from "../components/TechnicalInterview/TechnicalInterviewComponent_V1.1";
import { Loader } from "../components/loader";

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();

	const resReqFields = await ComunaAPI.getResReqFields(token);
	return resReqFields;
}


export default function ApplicationDetailView() {
	const { application_id }: any = useParams();
	const { resource_request_source }: any = useRouteLoaderData(
		"resource_request_detail"
	);

	const [skillOptions, setSkillOptions] = useState<any[]>([]);
	const [selectedSkills, setSelectedSkills] = useState<any[]>([]);

	const [application, setApplication] = useState<any>(null);
	const [interviewDetails, setInterviewDetails] = useState<any>(null);

	const [selectedTab, setSelectedTab] = useState(GetActiveTab()); // Set the initial tab


	const [contractor, setContractor] = useState<any>({
		first_name: "",
		last_name: "",
		title: "",
		email: "",
		phone_number: "",
		phone_country_indicator: "",
		salary_expectation: "",
		english_level: "1",
		seniority: "1",
		website: "",
		linkedin: "",
		cv: null,
		profile_picture: null,
		country_location: "",
		city_location: "",
		top_candidate: false,
		skills: ["js", "reacT"],
		note_for_client: "",
	});

	useEffect(() => {
		InitForm();
	}, []);

	useEffect(() => {
		let url = new URL(window.location.href);
		let searchParams = url.searchParams;

		// Remove existing parameters to avoid duplicates or incorrect values
		searchParams.delete('profile');
		searchParams.delete('messages');

		// Add new parameter based on the selected tab
		switch (selectedTab) {
			case 'profile':
				searchParams.append('profile', 'true');
				break;
			case 'messages':
				searchParams.append('messages', 'true');
				break;
			case 'interview':
				if (application.interview) {
					searchParams.append('interview', 'true');
					if (!application.interview.marked_view) {
						markInteviewViewed();
					}
				}
				break;
			default:
				break;
		}

		// Update the URL with the new parameters without reloading the page
		window.history.replaceState(null, '', `${url.pathname}?${searchParams.toString()}`);
	}, [selectedTab]);

	function GetActiveTab() {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('messages')) return 'messages';
		return 'profile'; // Default tab
	}

	async function InitForm() {
		const token = await LocalStorageManager.getToken();
		const userResReqReturn = await ComunaAPI.getResReqFields(token);

		if (userResReqReturn != null) {
			var newSkillOptions: any[] = [];
			userResReqReturn.skills.map((item: any) => {
				const newSkill = { label: item.name, value: item.id };
				newSkillOptions.push(newSkill);
			});
			await setSkillOptions(newSkillOptions);
		}

		//are we in editing mode? do we have an existing app id?
		if (application_id) {
			resource_request_source.applications.forEach((item: any) => {
				if (item.id === parseInt(application_id)) {
					setApplication(item);
					var selectedSkills_: any[] = [];

					for (let i = 0; i < item.contractor.skills.length; i++) {
						newSkillOptions.forEach((option: any) => {
							if (option.label === item.contractor.skills[i]) {
								selectedSkills_.push(option);
							}
						});
					}

					setSelectedSkills(selectedSkills_);

					item.contractor.profile_id = item.contractor.id; //this way when we submit the form, the profile_id triggers and update and not a create
					setContractor(item.contractor);

					if(item.interview)
						fetchInterviewDetails(item.interview.id);
				}
			});
		}

		setSelectedTab(GetActiveTab());
	}

	const fetchInterviewDetails = async (interviewId: any) => {
		const token = await LocalStorageManager.getToken();
		try {
			const interviewData = await ComunaAPI.GetInterview(token, interviewId);
			setInterviewDetails(interviewData);
		} catch (error) {
			console.error('Failed to fetch interview:', error);
		}
	};

	function Tab({ tab, label }: any) {
		return (
			<div
				onClick={() => { setSelectedTab(tab); }}
				className={`cursor-pointer rounded-t-xl bg-white text-sm font-semibold py-3 px-4 text-center ${selectedTab === tab ? 'border-b-4 border-comuna-purple' : ''}`}
			>
				{label}
			</div>
		)
	}

	async function markInteviewViewed() {
		if (!application.interview) return;

		var token = await LocalStorageManager.getToken();
		try {
			const result = await ComunaAPI.MakeInterviewViewed(token, application.interview.id);
			application.interview.marked_view = true;
			console.log('Success:', result);
		} catch (error) {
			console.error('Error:', error);
		}
	}

	return (
		<div>
			<ComunaSidePanel return_to={"/requests/" + resource_request_source.id}>

				{/** Tab Options  */}
				<div className="flex flex-row top justify-start w-full h-fit mx-auto border-b-2">
					<Tab label="Profile" tab="profile" />
					<Tab label="Messages" tab="messages" />
					{application && application.interview && application.interview.score > 0 &&
						<Tab
							tab="interview"
							label={
								<span className="flex flex-row">
									Technical Interview
									{!application.interview.marked_view && <span className="comuna-primary-button xss ml-3">NEW</span>}
								</span>
							}
						/>
					}
				</div>

				{selectedTab === "profile" && <ApplicationProfileTab application={application} contractor={contractor} />}
				{selectedTab === "messages" && <ApplicationmessageTab application={application} />}
				{selectedTab === 'interview' &&
					(interviewDetails ?
						<div className="w-full text-left flex flex-col flex-1" style={{ overflow: "hidden" }}>
							{
								!interviewDetails.question_summary || interviewDetails.question_summary.length === 0 ? (
									<TechnicalInterviewComponent
										interview={interviewDetails}
										agent={resource_request_source.comuna_agent}
										contractor={application.contractor}
									/>
								) : (
									<NewTechnicalInterviewComponent
										interview={interviewDetails}
										agent={resource_request_source.comuna_agent}
										contractor={application.contractor}
										application={application}
									/>
								)
							}
						</div>
						: (<Loader />))}

			</ComunaSidePanel>
			<Outlet />
		</div>
	);
}
